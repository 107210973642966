import { CSSProperties, useContext, useState, useEffect } from "react";
import Purchase from "../../models/Purchase.model";
import { toDatePT, toInputDate } from "../../utils/dates";
import { PrintingContext } from "../App";
import { CustomerDataTable } from "../customer/CustomerDataTable";
import EnrollmentsTable from "../enrollment/EnrollmentsTable";
import PaymentsTable, { PaymentFields } from "../payment/PaymentsTable";
import Card from "../shared/Card";
import ConfirmCancelIcons from "../shared/ConfirmCancelIcons";
import Loader from "../shared/Loader";
import CaretToggle from "../shared/CaretToggle";
import PrintPurchase from "./PrintPurchase";
import { api, getStorageInfo } from '../../services/api';
import { MessagingContext } from "../App";
import { PurchaseCustomerFields } from '../customer/CustomerDataTable';
import Customer from "../../models/Customer.model";
import Student from "../../models/Student.model";

interface PurchaseCardProps {
    purchase: Purchase;
    studentId?: number,
    changedData?: (purchase: Purchase) => void;
    removedData?: (purchaseId: number) => void;
    style?: CSSProperties;
    showStudentNames?: boolean;
}

type otherInformationFields = {
    start_date: string;
    observations: string;
    schedule: string;
    forecast_time: number;
    expiration_period: number;
}

export default function PurchaseCard(props: PurchaseCardProps) {
    const { purchase, studentId } = props;

    const messaging = useContext(MessagingContext);

    const [editing, setEditing] = useState(false);
    const printing = useContext(PrintingContext);
    const [student, setStudent] = useState<Student>();

    const [paymentsVisible, setPaymentsVisible] = useState(false);
    const [customerVisible, setCustomerVisible] = useState(false);
    const [otherInfoVisible, setOtherInfoVisible] = useState(false);

    const [paymentsEditMode, setPaymentsEditMode] = useState(false);
    const [customerEditMode, setCustomerEditMode] = useState(false);
    const [otherInfoEditMode, setOtherInfoEditMode] = useState(false);

    const [paymentFieldsValues, setPaymentFieldsValues] = useState<PaymentFields[]>([]);
    const [otherInfoFieldsValues, setOtherInfoFieldsValues] = useState<otherInformationFields>();
    const [customerFieldsValues, setCustomerFieldsValues] = useState<PurchaseCustomerFields>();

    useEffect(() => {
        if (studentId) {
            api.getStudentInfo(studentId, studentFetched => {
                setStudent(new Student(studentFetched));
            }, err => messaging.modal.showMessage("Erro", "Erro ao obter o aluno:" + err.message));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [studentId]);

    useEffect(() => {
        setOtherInfoFieldsValues({
            start_date: toInputDate(purchase.startDate),
            observations: purchase.observations,
            schedule: purchase.schedule,
            forecast_time: purchase.expectedTime,
            expiration_period: purchase.expirationPeriod,
        });
    }, [otherInfoEditMode, purchase.expectedTime, purchase.observations, purchase.schedule, purchase.startDate, purchase.expirationPeriod])

    useEffect(() => {
        setCustomerFieldsValues({
            name: "",
            email: "",
            phone: "",
            address: "",
            postal_code: "",
            city_id: 0,
            fiscal_number: 0,
            observations: "",
        });
    }, [customerEditMode])

    function submitPaymentsChanges() {
        const paymentsInfo = paymentFieldsValues.map((payment: PaymentFields) => ({
            id: payment.id,
            description: payment.description,
            payment_method_id: payment.methodId,
            payment_status_id: payment.statusTypeId,
            expiration_date: new Date(payment.expirationDate),
            value: payment.value,
            user_id: getStorageInfo().userId,
            observations: payment.observations
        }));

        api.editPayments(paymentsInfo,
            success => {
                setPaymentsEditMode(false);

                let clone: Purchase = purchase.clone();

                for (const payment of clone.payments) {
                    const paymentId = payment.id;
                    const sourceObj = paymentFieldsValues.find((data: any) => data.id === paymentId);

                    Object.assign(payment, sourceObj);

                }

                if (props.changedData) {
                    props.changedData(clone);
                    messaging.toast.show("Aviso", "Inscrição atualizada com sucesso!");
                }

            },
            err => {
                messaging.toast.show("Erro", "Erro a atualizar a inscrição! " + err);
            });

    }

    function submitCustomerChanges() {
        if (customerFieldsValues) {

            const customerInfo = {
                id: purchase.customer.id,
                name: customerFieldsValues.name,
                email: customerFieldsValues.email,
                phone: customerFieldsValues.phone,
                address: customerFieldsValues.address,
                postal_code: customerFieldsValues.postal_code,
                city_id: customerFieldsValues.city_id,
                fiscal_number: customerFieldsValues.fiscal_number ?? 0,
                observations: customerFieldsValues.observations,
            };

            api.editCustomer(customerInfo,
                success => {
                    setCustomerEditMode(false);

                    let clone: Purchase = purchase.clone();

                    const tempCustomer = new Customer(customerInfo);
                    clone.customer = tempCustomer;

                    if (props.changedData) {
                        props.changedData(clone);
                        messaging.toast.show("Aviso", "Inscrição atualizada com sucesso!");
                    }

                },
                err => {
                    messaging.toast.show("Erro", "Erro a atualizar a inscrição! " + err);
                });

        }
    }

    function handleRemovePurchase(purchase: Purchase) {
        messaging.modal.showFull({
            title: "Remover inscrição",
            body: `Tem a certeza que pretende remover a inscrição de ${purchase.studentName}?`,
            closeName: "Cancelar",
            closeHandler: messaging.modal.close,
            actionName: "Eliminar",
            actionHandler: () => {
                messaging.modal.close();
                deletePurchase(purchase.id);
            },
            actionClassName: "btn-danger",
            showAction: true
        });
    }

    function deletePurchase(id: number) {

        if (purchase) {

            api.deletePurchase(purchase.id,
                success => {

                    if (props.removedData) {
                        console.log(purchase);
                        props.removedData(purchase.id);

                        messaging.toast.show("Aviso", "Inscrição atualizada com sucesso!");
                    }
                },
                err => {
                    messaging.toast.show("Erro", "Erro a atualizar a inscrição! " + err);
                });

        }
    }

    function submitPurchaseChanges() {

        if (purchase && otherInfoFieldsValues) {
            setEditing(true);
            const purchaseInfo = {
                id: purchase.id,
                ...otherInfoFieldsValues
            }

            api.editPurchase(purchaseInfo,
                success => {
                    setEditing(false);
                    setOtherInfoEditMode(false);

                    let clone: Purchase = purchase.clone();
                    clone.startDate = new Date(otherInfoFieldsValues.start_date);
                    clone.schedule = otherInfoFieldsValues.schedule;
                    clone.observations = otherInfoFieldsValues.observations;
                    clone.expectedTime = otherInfoFieldsValues.forecast_time;
                    clone.expirationPeriod = otherInfoFieldsValues.expiration_period;

                    if (props.changedData) {
                        props.changedData(clone);
                        messaging.toast.show("Aviso", "Inscrição atualizada com sucesso!");
                    }

                },
                err => {
                    setEditing(false);
                    messaging.toast.show("Erro", "Erro a atualizar a inscrição! " + err);
                });

        }
    }

    function isCardBeingEdited() {
        return customerEditMode || otherInfoEditMode || paymentsEditMode;
    }

    function printEnrollment() {
        if (student) {
            printing.showDocument(
                <PrintPurchase purchase={purchase}
                    close={printing.close}
                    student={student} />);
        }
    }
    return (
        <Card
            keyId={purchase.id.toString()}
            title={
                <div>
                    <span>{purchase.description()}</span>
                    <span style={{ marginLeft: "10px", fontSize: "0.90rem", color: "#585858" }}>
                        {toDatePT(purchase.createdDate)}
                    </span>
                    <i className="fas fa-print action-icon ml-10" onClick={printEnrollment} title="Imprimir Ficha de Inscrição"></i>
                    <i className="fas fa-trash action-icon ml-10" onClick={() => handleRemovePurchase(purchase)} title="Eliminar"></i>
                </div>
            }
            includeHeader={true}
            width={isCardBeingEdited() ? 1000 : 700}
            style={props.style || {}}
            body={
                <>
                    <h5 className="card-sub-title" style={{ margin: "0px 0px 10px 0px" }}>Cursos</h5>
                    <EnrollmentsTable enrollments={purchase.enrollments} showStudent={props.showStudentNames} />

                    <div className="card-sub-title" style={{ marginBottom: "10px" }}>
                        <span
                            className=" cursor-pointer"
                            style={{ margin: "30px 0px 10px 0px" }}
                            onClick={() => setPaymentsVisible(val => !val)}>
                            Pagamentos
                        </span>
                        <CaretToggle visible={paymentsVisible} onClick={() => setPaymentsVisible(val => !val)} />
                        {paymentsEditMode ?
                            (editing ?
                                <Loader
                                    outerClassName=""
                                    outterStyle={{ display: "inline" }}
                                    style={{ marginLeft: "10px" }}
                                    size="small" /> :
                                <ConfirmCancelIcons
                                    confirmHandler={() => submitPaymentsChanges()}
                                    cancelHandler={() => setPaymentsEditMode(false)} />
                            ) :
                            <i
                                className="fas fa-pen action-icon"
                                style={{ margin: "5px 0 0 10px" }}
                                title="Editar"
                                onClick={() => setPaymentsEditMode(true)}></i>
                        }
                    </div>
                    {paymentsVisible &&
                        (purchase.payments.length > 0 ?
                            <PaymentsTable
                                editMode={paymentsEditMode}
                                values={purchase.payments}
                                updatePaymentData={(e: PaymentFields[]) => setPaymentFieldsValues(e)}
                            /> :
                            <p>Não há pagamentos disponiveis para esta inscrição</p>)
                    }

                    <div className="card-sub-title" style={{ marginBottom: "10px" }}>
                        <span
                            className=" cursor-pointer"
                            style={{ margin: "30px 0px 10px 0px" }}
                            onClick={() => setCustomerVisible(val => !val)}>
                            Faturação
                        </span>
                        <CaretToggle visible={customerVisible} onClick={() => setCustomerVisible(val => !val)} />
                        {customerEditMode ?
                            (editing ?
                                <Loader
                                    outerClassName=""
                                    outterStyle={{ display: "inline" }}
                                    style={{ marginLeft: "10px" }}
                                    size="small" /> :
                                <ConfirmCancelIcons
                                    confirmHandler={() => submitCustomerChanges()}
                                    cancelHandler={() => setCustomerEditMode(false)} />
                            ) :
                            <i
                                className="fas fa-pen action-icon"
                                style={{ margin: "5px 0 0 10px" }}
                                title="Editar"
                                onClick={() => setCustomerEditMode(true)}></i>
                        }
                    </div>
                    {customerVisible &&
                        <CustomerDataTable
                            customer={purchase.customer}
                            updateCustomerData={(e: PurchaseCustomerFields) => setCustomerFieldsValues(e)}
                            editMode={customerEditMode}
                            showResponsible={false} />
                    }



                    <div className="card-sub-title" style={{ marginBottom: "10px" }}>
                        <span
                            className=" cursor-pointer"
                            style={{ margin: "30px 0px 10px 0px" }}
                            onClick={() => setOtherInfoVisible(val => !val)}>
                            Outras informações
                        </span>
                        <CaretToggle visible={customerVisible} onClick={() => setOtherInfoVisible(val => !val)} />
                        {otherInfoEditMode ?
                            (editing ?
                                <Loader
                                    outerClassName=""
                                    outterStyle={{ display: "inline" }}
                                    style={{ marginLeft: "10px" }}
                                    size="small" /> :
                                <ConfirmCancelIcons
                                    confirmHandler={() => submitPurchaseChanges()}
                                    cancelHandler={() => setOtherInfoEditMode(false)} />
                            ) :
                            <i
                                className="fas fa-pen action-icon"
                                style={{ margin: "5px 0 0 10px" }}
                                title="Editar"
                                onClick={() => setOtherInfoEditMode(true)}></i>
                        }
                    </div>
                    {otherInfoVisible &&
                        <table className="table table-striped">
                            <tbody>
                                <tr>
                                    <td>Data de inscrição</td>
                                    <td>
                                        {otherInfoEditMode && otherInfoFieldsValues ?
                                            <input
                                                type="date"
                                                name="start_date"
                                                className="form-control"
                                                value={otherInfoFieldsValues.start_date}
                                                onChange={(e) => setOtherInfoFieldsValues({ ...otherInfoFieldsValues, "start_date": e.target.value })} />
                                            :
                                            toDatePT(purchase.startDate)}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Horário</td>
                                    <td>
                                        {otherInfoEditMode && otherInfoFieldsValues ?
                                            <input
                                                type="text"
                                                name="schedule"
                                                className="form-control"
                                                value={otherInfoFieldsValues.schedule}
                                                onChange={(e) => setOtherInfoFieldsValues({ ...otherInfoFieldsValues, "schedule": e.target.value })} />
                                            :
                                            purchase.schedule}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Carga horária estimada</td>
                                    <td>
                                        {otherInfoEditMode && otherInfoFieldsValues ?
                                            <input
                                                type="number"
                                                name="expectedTime"
                                                className="form-control"
                                                value={otherInfoFieldsValues.forecast_time}
                                                onChange={(e) => setOtherInfoFieldsValues({ ...otherInfoFieldsValues, "forecast_time": parseInt(e.target.value) })} />
                                            :
                                            `${purchase.expectedTime}h`
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Periodo de Validade</td>
                                    <td>
                                        {otherInfoEditMode && otherInfoFieldsValues ?
                                            <input
                                                type="number"
                                                name="expirationPeriod"
                                                className="form-control"
                                                value={otherInfoFieldsValues.expiration_period}
                                                onChange={(e) => setOtherInfoFieldsValues({ ...otherInfoFieldsValues, "expiration_period": parseInt(e.target.value) })} />
                                            :
                                            `${purchase.expirationPeriod} meses`
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Observações</td>
                                    <td>
                                        {otherInfoEditMode && otherInfoFieldsValues ?
                                            <input
                                                type="text"
                                                name="observations"
                                                className="form-control"
                                                value={otherInfoFieldsValues.observations}
                                                onChange={(e) => setOtherInfoFieldsValues({ ...otherInfoFieldsValues, "observations": e.target.value })} />
                                            :
                                            purchase.observations}
                                    </td>
                                </tr>
                            </tbody>
                        </table>}
                </>
            } />
    )
}